const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export const getMatchDateMMMDD = (ts: string) => {
  const date = new Date(parseInt(ts)*1000);
  return `${months[date.getMonth()]} ${date.getDate()} ${date.toLocaleTimeString().substring(0,5)} `
};

export interface CountdownInterface {
  d: number;
  h: number;
  m: number;
  s: number;
}

export const getCountdown = (ts: string) => {
  const milliseconds = parseInt(ts)*1000 - Date.now();
  let days, hours, minutes, seconds, totalHours, totalMinutes, totalSeconds;
  
  totalSeconds = Math.floor(milliseconds / 1000);
  totalMinutes = Math.floor(totalSeconds / 60);
  totalHours = Math.floor(totalMinutes / 60);
  days = Math.floor(totalHours / 24);

  seconds = totalSeconds % 60;
  minutes = totalMinutes % 60;
  hours = totalHours % 24;
  
  return { d: days, h: hours, m: minutes, s: seconds };
}