import styled from "styled-components";
import Button from "@mui/material/Button";
import ToggleButton from "@mui/material/ToggleButton";
import { colorButtonGradientStart, colorButtonGradientEnd, colorVeryLightAzure, colorDarkBlue } from "./colors";

export const PrimaryButton = styled(Button)`
  color: white !important;
  text-transform: none !important;
  background: linear-gradient(91.79deg, ${colorButtonGradientStart} 1.51%, ${colorButtonGradientEnd} 99.12%) !important;
`;

export const TeamButton = styled(ToggleButton)`
  color: ${colorVeryLightAzure} !important;
  border-color: ${colorVeryLightAzure} !important;
  text-transform: none !important;
  border-radius: 4px !important;
  padding: 0.625em 1em !important;
  display: flex !important;
  justify-content: flex-start !important;
  font-weight: 400 !important;

  &.Mui-selected {
    color: ${colorDarkBlue} !important;
    background-color: ${colorVeryLightAzure} !important;
  }
`;
