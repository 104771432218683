import * as React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { createGlobalStyle } from 'styled-components';
import { colorDarkBlue, colorButtonGradientStart, colorButtonGradientEnd } from '../shared-css/colors';
import { NONAME } from 'dns';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif',
    h1: { // 5700 USD
      fontWeight: 700,
      fontSize: 24,
    },
    h2: { // Sports meets crypto
      fontWeight: 500,
      fontSize: 24,
    },
    h3: { // My account
      fontWeight: 500,
      fontSize: 16,
    },
    h4: {
      fontWeight: 400,
      fontSize: 16,
    },
    h5: { // banner bold
      fontWeight: 500,
      fontSize: 12,
    },
    body1: { // Reward pool
      fontWeight: 400,
      fontSize: 14,
    },
    body2: { // Follow your favorite team
      fontWeight: 500,
      fontSize: 14,
    },
    caption: { // 20 days 5hs 30 min
      fontWeight: 400,
      fontSize: 12,
    },
    overline: {
      fontWeight: 500,
      fontSize: 12,
      textDecoration: 'underline',
      textTransform: 'none',
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 10,
    },
    button: {
      fontWeight: 500,
      fontSize: 14,
    }
  },
  palette: {
    mode: "dark"
  }
});

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: sans-serif;
  }

  body {
    margin: 0;
    padding: 0;
    color: white;
    background-color: ${colorDarkBlue};
  }

  h1, h2 {
    line-height: 36px;
  }

  h3, h4 {
    line-height: 24px;
  }

  body1, body2, button {
    line-height: 21px;
  }
  
  caption {
    line-height: 18px;
  }

  subtitle1 {
    line-height: 15px;
  }

  a {
    cursor: pointer;
  }
`;

export const GamblifyTheme = ({children}) => <ThemeProvider theme={theme}>
  <GlobalStyle />
  {children}
  </ThemeProvider>;