
import styled from "styled-components";

export const TeamFlag = styled.img<{flag: string}>`
  border: 1px solid #FFFFFF;
  border-radius: 6px;
  background: url(${(props) => props.flag});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 4.3em;
  height: 3em;
`;