import React from 'react';
import { MatchInterface } from '../types/match';
import { transformOptionBetsPayload } from '../adapters/optionBetsAdapter';

interface Props {
  children: React.ReactNode;
}

export interface MatchesContextInterface {
  matches: MatchInterface[];
}

export const MatchesContext = React.createContext<MatchesContextInterface>(undefined!);

export const MatchesContextProvider = ({ children }: Props) => {
  const [matches, setMatches] = React.useState<MatchInterface[] | null>(null);

  React.useEffect(() => {
    fetch(process.env.REACT_APP_MATCHES_API)
      .then(response => response.json())
      .then(data => {
        const transformedPayload = transformOptionBetsPayload(data);
        setMatches(transformedPayload);
      })
      .catch(error => console.error(error));
  }, []);

  return (
    <MatchesContext.Provider value={{
      matches
    }}>
      {/* !matches && Loading */}
      { matches && children }
    </MatchesContext.Provider>
  );
};

MatchesContext.displayName = 'MatchesContext';