import React from 'react';
import styled from 'styled-components';
import { useNavigate } from "react-router-dom";
import { Typography, Link } from '@mui/material';
import { Matches } from './matches/Matches';
import { ContentWrapper } from '../shared-css/content-wrapper';
import { Header } from './shared/Header';
import { colorGradientStart, colorGradientEnd } from '../shared-css/colors';
import { WalletContext } from '../context/WalletContext';

const Intro = styled.div`
  background: linear-gradient(102.24deg, ${colorGradientStart} 0%, ${colorGradientEnd} 100%);
  border-radius: 8px;
  margin-top: 22px;
  padding: 16px;
  width: 100%;

  a {
    margin-top: 20px;
    display: block;
  }
`;

export const Home = () => {
  let navigate = useNavigate();
  const { wallet } = React.useContext(WalletContext);

  React.useEffect(() => {
    if(!wallet) {
      navigate("connect");
    }
  }, [wallet])
  
  return (
     <>
      {wallet && (<>
        <Header />
        <ContentWrapper>
          <Typography variant="h2">Sport meets crypto</Typography>
          <Intro>
            <Typography variant="body2">
              Follow you favorite team! Play at individual 
              events ar for the 2022 World Cup and earn
              points and crypto. 
              (BETA)
            </Typography>
            <Link variant="body2" color={'inherit'}>Play to earn</Link>
          </Intro>
          <Matches />
        </ContentWrapper>
      </>)}
     </>
  );
}

