import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from '@react-spring/web';

import { SplashScreen } from './components/SplashScreen';
import { App } from './App';

const SplashScreenWrapper = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`

function AppWrapper() {
  const [hideSplash, setHideSplash] = React.useState(false);
  
  const splashProps = useSpring({ 
    to: { opacity: 0 }, 
    from: { opacity: 1 }, 
    delay: 500,
    config: {
      duration: 1500,
      velocity: 3
    }
  });

  React.useEffect(() => {
    setTimeout(() => {
      setHideSplash(true);
    }, 2000)
  });

  return (
    <>
      {/* {!hideSplash && <SplashScreenWrapper style={splashProps}>
        <SplashScreen />
      </SplashScreenWrapper>} */}
      <App />
    </>
  );
}

export default AppWrapper;
