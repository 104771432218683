import * as React from 'react';
import styled from "styled-components";
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { HeaderWrapper } from '../../shared-css/header';
import { MatchInterface } from '../../types/match';

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Back = styled(Link)`
  height: 24px;
  margin-right: 1em !important;
`;

interface Props {
  match: MatchInterface;
};

export const Header = ({ match }: Props) => {
  return (
    <HeaderWrapper>
      <LeftWrapper>
        <Back href='/' variant="body2" color={'inherit'}><ArrowBack /></Back>
        <Typography variant='h3'>{match.local.code} vs {match.visitor.code}</Typography>
      </LeftWrapper>
    </HeaderWrapper>
  );
}
