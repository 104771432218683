import { MatchInterface } from "../types/match";

export const transformOptionBetsPayload = (payload: any) => {
  let matches = [];

  for(let i = 1; i < payload.values.length; i++) {
    if(payload.values[i].length>0){
      try {
        let match: MatchInterface = {
          id: payload.values[i][3],
          date: payload.values[i][4],
          local: {
            teamId: payload.values[i][12],
            name: payload.values[i][13],
            flag: payload.values[i][14],
            code: payload.values[i][13].substring(0, 3),
          },
          visitor: {
            teamId: payload.values[i][15],
            name: payload.values[i][16],
            flag: payload.values[i][17],
            code: payload.values[i][16].substring(0, 3),
          },
          betAmount: payload.values[i][6],
          startingPool: payload.values[i][7],
          results: {},
          title: payload.values[i][9],
          category: payload.values[i][18],
          tournament: payload.values[i][19],
          testnet: payload.values[i][11] === "TRUE" ? true : false,
          mainnet: payload.values[i][10] === "TRUE" ? true : false,
          currency: payload.values[i][20],
          winner: payload.values[i][21],
          ended: payload.values[i][23] === "TRUE" ? true : false
        };
    
        payload.values[i][8].split(",").forEach((str: string) => {
          const key = str.split(":")[1];
          const value = parseInt(str.split(":")[0]);
          match.results[key] = value;
        })
        
        matches.push(match);
                
      } catch (error) {
        console.log(error)
      }
    }
    
  }

  return matches.sort(function (a,b) {
    if (a.date < b.date) {    
      return -1;    
    }    
    if (a.date> b.date) {    
      return 1;    
    }    
    return 0;    
  });
}
