export const colorDarkBlue = '#131C2E';
export const colorMediumBlue = '#172E5B';
export const colorLightBlue = '#2B4D93';
export const colorVeryLightAzure = '#7BBEF5';
export const colorGradientStart = '#255FD3';
export const colorGradientEnd = '#163F8F';
export const colorButtonGradientStart = "#35A0F7";
export const colorButtonGradientEnd = "#1B79F5";
export const colorBrightBlue = '#2284F5';
export const colorSectionBackground = '#f4f4f4';
