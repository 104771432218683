import React from 'react';
import ReactDOM from 'react-dom';
import AppWrapper from './AppWrapper';
import { EthereumContextProvider } from './context/EthereumContext';
import { BrowserRouter } from 'react-router-dom';
import { GamblifyTheme } from './context/ThemeContext';
import { WalletContextProvider } from './context/WalletContext';

ReactDOM.render(
  <React.StrictMode>
    <GamblifyTheme>
      <WalletContextProvider>
        <BrowserRouter>
          <AppWrapper />
        </BrowserRouter>
      </WalletContextProvider>
    </GamblifyTheme>
  </React.StrictMode>,
  document.getElementById('root')
);
