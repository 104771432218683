import React from 'react';
import styled from 'styled-components';
import { Typography, Link } from '@mui/material';
import { ContentWrapper } from '../shared-css/content-wrapper';
import { colorGradientStart, colorGradientEnd } from '../shared-css/colors';
import { ReactComponent as PolygonLogo } from '../images/svg/polygon-token.svg';
import { ReactComponent as MetamaskLogo } from '../images/svg/metamask.svg';
import { ReactComponent as WalletConnectLogo } from '../images/svg/walletconnect.svg';
import { WalletContext, SelectedProvider } from '../context/WalletContext';
import { PrimaryButton } from '../shared-css/button';
import { useNavigate } from 'react-router-dom';


const TitleWrapper = styled.div`
  margin-top: 5em;
  margin-bottom: 3em;
  text-align: center;
`;

const Intro = styled.div`
  background: rgb(0 0 0 / 29%);
  padding: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
`;

const Section = styled.div`
  padding: 0 1em;
  margin-bottom: 3em;
  display: flex;
  align-items: start;
  height: 62px;

  button {
    margin-top: 9px;
  }
`;

const Polygon = styled(PolygonLogo)`
  width: 48px;
  height: auto;
  margin-right: 1em;
`
const LogoWrapper = styled.div`
  min-width: 52px;
  margin-right: 1em;
`;

const Metamask = styled(MetamaskLogo)`
  width: 52px;
  height: 46px;
`;

const WalletConnect = styled(WalletConnectLogo)`
  width: 52px;
  height: auto;
`;

const MetamaskSection = styled.div`
  display: flex;
  flex-direction: column;

  a {
    margin-bottom: 1em;
  }
`;

export const Connect = () => {
  let navigate = useNavigate();
  const { provider, wallet, setSelectedProvider, selectedProvider, supportedNetwork } = React.useContext(WalletContext);

  React.useEffect(() => {
    if (wallet && supportedNetwork) {
      navigate("../");
    }
  }, [wallet, supportedNetwork]);

  return (
    <>
      {!supportedNetwork && (
        <Intro>
          <Polygon />
          <div>
            <Typography variant="h5">
              Right now only Polygon (MATIC) network is supported. <br/>
            </Typography>
            <Typography variant="overline">
              Please switch to Polygon and refresh the app.
            </Typography>
          </div>
        </Intro>
      )}
      <ContentWrapper>
        <TitleWrapper>
          <Typography variant="h2">
            Connect to play
          </Typography>
        </TitleWrapper>

        {(!wallet || !supportedNetwork) && ( <>
          <Section>
            <LogoWrapper>
              <Metamask />
            </LogoWrapper>          
            <div>
              {selectedProvider === SelectedProvider.Metamask && !provider && (
                <MetamaskSection>
                  <Typography variant="h4">
                    You need Metamask to play!
                  </Typography>
                  <Typography variant='caption'>Once installed, please refresh this app.<br/></Typography>
                  <Link variant="caption" href="https://www.youtube.com/watch?v=Af_lQ1zUnoM" target={'_blank'}>How to install</Link>
                  <Link onClick={() => setSelectedProvider(null)}variant="body2" color={'inherit'}>Back</Link>
                </MetamaskSection>
              )}
              {!selectedProvider && <PrimaryButton variant="contained" onClick={() => setSelectedProvider(SelectedProvider.Metamask)}>Connect with Metamask</PrimaryButton>}
            </div>
          </Section>
          {!selectedProvider && 
          <Section>
            <LogoWrapper>
              <WalletConnect />
            </LogoWrapper>          
            <div>
              <PrimaryButton variant="contained" onClick={() => setSelectedProvider(SelectedProvider.WalletConnect)}>Connect with WalletConnect</PrimaryButton>
            </div>
          </Section>
          }
          </>
        )}
      </ContentWrapper>
    </>
  );
}
