import React from 'react';
import styled from 'styled-components';
import { BigNumber, ethers } from 'ethers';
import Fade from '@mui/material/Fade';
import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from "@mui/lab/LoadingButton";

import { MatchInterface } from '../../types/match';
import { WalletContext } from '../../context/WalletContext';
import StableCoin from '../../contracts/tUSDT.json';
import { TeamButton, PrimaryButton } from '../../shared-css/button';
import { TeamFlag } from '../../shared-css/flag';
import { tie } from '../../images/png/tie.png';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const TeamFlagSmall = styled(TeamFlag)`
  width: 2.25em;
  height: 1.5625em;
  border-radius: 2px;
  margin-right: 1.26em;
`

const TeamsChoiceWrapper = styled.div`
  margin: 1em 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  button {
    margin-bottom: 1em ;
  }
`

const OptionStatus = styled.span`
  position: absolute;
  right: 1em;
  display: flex;
`;

const ByPenaltiesWrapper = styled.span`
  margin-top: 1.25em;
  
  button {
    width: 100%;
  }
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    width: 50%;

    &:first-of-type {
      margin-right: 1.25em;
    }
  }
`;

const ApprovedUSDT = styled(TeamButton)`
  padding: 0.5em 1em !important;
`;

interface Props {
  match: MatchInterface;
  contractWithSigner: any;
}

export const UserBet = ({ contractWithSigner, match }: Props) => {
  const [result, setResult] = React.useState<number | null>(null);
  const { provider, signer , wallet} = React.useContext(WalletContext);
  const [loadingUserBet, setLoadingUserBet] = React.useState(false);
  const [loadingApprove, setLoadingApprove] = React.useState(false);
  const [approvedUSDT, setApprovedUSDT] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [tx, setTx] = React.useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let USDTContract = new ethers.Contract(process.env.REACT_APP_POLYGON_STABLE_COIN_ADDRESS, StableCoin.abi, provider);
  let USDTContractWithSigner = USDTContract.connect(signer);

  const RESULTS = JSON.parse(process.env.REACT_APP_RESULTS)

  const approve = () => {
      let newBetAmout = BigNumber.from(match.betAmount);

      USDTContractWithSigner.decimals().then((decimals)=>{

        USDTContractWithSigner.approve(process.env.REACT_APP_OPTIONS_BET_ADDRESS, newBetAmout).then((tx) => {
          console.log("Approve TX: ", tx)
          tx.wait().then(function (response){
            console.log("TX: Confirmed", response);
            setApprovedUSDT(true);
          });
         // TODO: Mostrar un loading hasta que el approve tx se confirme
         // TODO: En caso de retornar error mostrarlo. 
          
        })
        .catch(error => console.error(error))
        .finally(() => setLoadingApprove(false))
      })
      
  };

  const userBet = () => {
    setLoadingUserBet(true);

    contractWithSigner.bet(match.id, [result])
      .then(tx => {
        console.log("User bet on result: "+ result)
        console.log("Bet TX Sent: ", tx)
        tx.wait().then(function (response){
          console.log("Bet TX: Confirmed", response);
          //TODO: Actualizar el rewardPool y la cantidad de players (Protip: hacerlo a mano para no hacer otra llamada)
          setTx(tx.hash)
          handleOpen()

        });
      }).catch(error => {
        console.error(error)
      })
      .finally(() => setLoadingUserBet(false))
  };

  return (
    <>
      <TeamsChoiceWrapper>
        <TeamButton selected={result === RESULTS.localWin} value={RESULTS.localWin} aria-label="local wins" onChange={() => {
          result !== RESULTS.localWin ? setResult(RESULTS.localWin) : setResult(null);

        }}>
          <TeamFlagSmall flag={match.local.flag} />
          {match.local.name}
          <OptionStatus>{result === RESULTS.localWin && <CheckIcon />}</OptionStatus>
        </TeamButton>
        {match.results.draw && <TeamButton selected={result === RESULTS.draw} value={RESULTS.draw} aria-label="draw" onChange={() => {
          result !== RESULTS.draw ? setResult(RESULTS.draw) : setResult(null);

        }}>
          <TeamFlagSmall flag={tie} />
          Draw
          <OptionStatus>{result === RESULTS.draw && <CheckIcon />}</OptionStatus>
        </TeamButton>}
        <TeamButton selected={result === RESULTS.visitorWin} value={RESULTS.visitorWin} aria-label="visitor wins" onChange={() => {
          result !== RESULTS.visitorWin ? setResult(RESULTS.visitorWin) : setResult(null);

        }}>
          <TeamFlagSmall flag={match.visitor.flag} />
          {match.visitor.name}
          <OptionStatus>{result === RESULTS.visitorWin && <CheckIcon />}</OptionStatus>
        </TeamButton>
        {match.results.localWinByPenalty && <ByPenaltiesWrapper>
          <TeamButton selected={result === RESULTS.localWinByPenalty} value={RESULTS.localWinByPenalty} aria-label="local wins by penalties" onChange={() => {
            result !== RESULTS.localWinByPenalty ? setResult(RESULTS.localWinByPenalty) : setResult(null);

          }}>
            <TeamFlagSmall flag={match.local.flag} />
            {match.local.name} by penalties
            <OptionStatus>{result === RESULTS.localWinByPenalty && <CheckIcon />}</OptionStatus>
          </TeamButton>
          <TeamButton selected={result === RESULTS.visitorWinByPenalty} value={RESULTS.visitorWinByPenalty} aria-label="visitor wins by penalties" onChange={() => {
            result !== RESULTS.visitorWinByPenalty ? setResult(RESULTS.visitorWinByPenalty) : setResult(null);

          }}>
            <TeamFlagSmall flag={match.visitor.flag} />
            {match.visitor.name} by penalties
            <OptionStatus>{result === RESULTS.visitorWinByPenalty && <CheckIcon />}</OptionStatus>
          </TeamButton>
        </ByPenaltiesWrapper>}
      </TeamsChoiceWrapper>
      <Fade in={!!result}>
        <ActionsWrapper>
          {!loadingApprove && !approvedUSDT && <PrimaryButton
            variant="contained"
            onClick={approve}
          >
            Approve USDT
          </PrimaryButton>}
          {!loadingApprove && approvedUSDT && <ApprovedUSDT value="" selected>
            Approve USDT
            <OptionStatus><CheckIcon /></OptionStatus>
          </ApprovedUSDT>}
          {loadingApprove && <LoadingButton
            loading={true}
            variant="contained"
          >
          </LoadingButton>}
          {!approvedUSDT && <LoadingButton
            loading={loadingUserBet}
            variant="contained"
            disabled
          >
            Play
          </LoadingButton>}
          {approvedUSDT && <PrimaryButton
            variant="contained"
            onClick={userBet}
          >
            Play
          </PrimaryButton>}
        </ActionsWrapper>
      </Fade>
      <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Congratulations!
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Your bet has been submitted.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Tx: {tx}
          </Typography>
        </Box>
      </Modal>
    </div>
    </>
  );
}
