import React from 'react';
import styled from 'styled-components';
import { ethers } from 'ethers';
import { Typography } from '@mui/material';

import { MatchInterface } from '../../types/match';
import { colorMediumBlue, colorBrightBlue } from '../../shared-css/colors';
import { WalletContext } from '../../context/WalletContext';
import { getMatchDateMMMDD } from '../../utils/match-date'
import { PrimaryButton } from '../../shared-css/button';
import { MatchCountdown } from '../shared/MatchCountdown';
import { RewardPool } from '../shared/RewardPool';


const MatchWrapper = styled.div`
  background-color: ${colorMediumBlue};
  border: 1px solid ${colorBrightBlue};
  border-radius: 8px;
  padding: 1em;
  margin: 1.3em 0;
  width: 100%;
`;

const MatchSection = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5em;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const TeamsWrapper = styled.div`
  display: flex;
`;

const Team = styled.div`
  padding-right: 1em;
  &:last-of-type {
    padding-right: 0;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TeamFlag = styled.img<{flag: string}>`
  border: 1px solid #FFFFFF;
  border-radius: 6px;
  background: url(${(props) => props.flag});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 4.3em;
  height: 3em;
`;

const DateWrapper = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
`;

interface Props {
  match: MatchInterface;
};

export const MatchOverview = ({ match }: Props) => {
  const { provider, signer, wallet } = React.useContext(WalletContext);
  const [contractWithSigner, setContractWithSigner] = React.useState(null);
  const [rewardPool, setRewardPool] = React.useState(0);
  const [pastEvent, setPastEvent] = React.useState<boolean>(false);
  const [hasResult, setHasResult] = React.useState<boolean>(false);
  const [winnerOption, setWinnerOption] = React.useState<number>(0);
  const [playerWinnerOption, setPlayerWinnerOption] = React.useState<number>(0);
  const [userClaimed, setUserClaimed] =  React.useState<boolean>(false);

  const claim = () => {
    contractWithSigner.claim(match.id).then(function (tx){
      console.log("Claim Sent:", tx)
      tx.wait().then(function (txConfirmed){
        console.log("Claim Confirmed:", txConfirmed)
        setUserClaimed(true);
      })
    }).catch(function(error){
      setUserClaimed(true);
      //TODO: Setear en true si el error es que quiere reclamar 2 veces...por bug en el smart contract no podemos dispararlo.
      console.log(error)
    })

    
  }

  React.useEffect(() => {
    if(wallet) {
      let contract = new ethers.Contract(process.env.REACT_APP_OPTIONS_BET_ADDRESS, process.env.REACT_APP_OPTIONS_BET_ABI, provider)
      setContractWithSigner(contract.connect(signer))
      contract.definitions(match.id).then(function(response){
        setHasResult(response.winnerOption != 0)
        setWinnerOption(response.winnerOption)
        if (response.winnerOption != 0){
          contract.bets(match.id, wallet).then(function(userBet){
            setPlayerWinnerOption(userBet);
          })
        }
      })
    }
    setPastEvent(parseInt(match.date) < (Date.now()/1000));



  }, [wallet]);

  return (
    <MatchWrapper>
      <MatchSection>
        {contractWithSigner && <RewardPool contractWithSigner={contractWithSigner} match={match} rewardPool={rewardPool} setRewardPool={setRewardPool} titleSize="body2" valueSize="h1"/>}
        <TeamsWrapper>
          <Team>
            <TeamFlag flag={match.local.flag} />
            <Typography variant="caption">{match.local.code}</Typography>
          </Team>
          <Team>
            <TeamFlag flag={match.visitor.flag} />
            <Typography variant="caption">{match.visitor.code}</Typography>
          </Team>
        </TeamsWrapper>
      </MatchSection>
      <MatchSection>
        <Typography variant="h2">{match.title}</Typography>
      </MatchSection>
      <MatchSection>
        <DateWrapper>
          <Typography variant="body1">{getMatchDateMMMDD(match.date)}</Typography>
          <MatchCountdown match={match} />
        </DateWrapper>
        {wallet && !pastEvent && <PrimaryButton href={`/match/${match.id}`} variant="contained">Place Bet</PrimaryButton>}
        {wallet && !userClaimed && hasResult  && winnerOption != 0 && winnerOption == playerWinnerOption &&<PrimaryButton  onClick={()=> claim()} variant="contained" >Claim</PrimaryButton>}
        
      </MatchSection>
    </MatchWrapper>
  );
}
