import React from 'react';
import { Route, Routes } from 'react-router';
import styled from 'styled-components';
import { Connect } from './components/Connect';
import { Home } from './components/Home';
import { Match } from './components/matches/Match';
import { MatchesContextProvider } from './context/MatchesContext';

const AppContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const App = () => {
  return (
    <MatchesContextProvider>
      <AppContainer>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="connect" element={<Connect />} />
          <Route path="match/:matchId" element={<Match />} />
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>There's nothing here!</p>
              </main>
            }
          />
        </Routes>
      </AppContainer>
    </MatchesContextProvider>
  );
}
