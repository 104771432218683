import React from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { MatchInterface } from '../../types/match';
import Fade from '@mui/material/Fade';
import { BigNumber } from 'ethers';
import CircularProgress from '@mui/material/CircularProgress';

const Spinner = styled(CircularProgress)`
  width: 1.5em !important;
  height: 1.5em !important;
`;

interface Props {
  match: MatchInterface;
  contractWithSigner: any;
  rewardPool: number;
}

export const PlayersCount = ({ contractWithSigner, match, rewardPool }: Props) => {
  const [percentage, setPercentage] = React.useState<number>(0);
  const [loading, setLoading] = React.useState(true);
  const betAmount = parseInt(match.betAmount);

  const getMatchDefinitions = () => {
    contractWithSigner.definitions(match.id)
      .then(response => {
        const fee = BigNumber.from(response.fee._hex);
        const feeInt = fee.toNumber();
        const feePercentage = (100 - feeInt) / 100;
        setPercentage(feePercentage);
      }).catch(error => {
        console.error(error)
      })
      .finally(() => setLoading(false));
  }

  React.useEffect(() => {
    getMatchDefinitions();
  }, []);

  return (
    <>
      {loading && <Spinner />}
      {!loading && <Fade in={!loading}><Typography variant="h4">{percentage && betAmount ? Math.ceil(rewardPool / betAmount * percentage) : 0 } players</Typography></Fade>}
    </>
  );
}
