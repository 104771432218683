import React from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import { MatchInterface } from '../../types/match';
import { getCountdown } from '../../utils/match-date';

interface Props {
  match: MatchInterface;
}

export const MatchCountdown = ({ match }: Props) => {
  const [countdown , setCountdown] = React.useState<string>('');
  const [pastEvent, setPastEvent] = React.useState<boolean>(false);
  
  React.useEffect(() => {
    const remaining = getCountdown(match.date);
    remaining && setCountdown(`${remaining.d} days ${remaining.h} hs ${remaining.m} min`);

    setPastEvent(parseInt(match.date) < (Date.now()/1000));
    setTimeout(() => {
      const remaining = getCountdown(match.date);
      remaining && setCountdown(`${remaining.d} days ${remaining.h} hs ${remaining.m} min`);
    }, 60000); // refresh per minute
  }, []);

  return (//TODO: Agregar segfundos al countdown y hacerlo un poco mas gris la tipografia
  <>
  {!pastEvent && <Typography variant="caption">Countdown: {countdown}</Typography>}
  {pastEvent && <Typography variant="caption">Ended</Typography>}
  </>
    
  );
}
