import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { HeaderWrapper } from '../../shared-css/header';
import { Alert } from '@mui/material';
import { WalletContext } from '../../context/WalletContext';

export const Header = () => {
  const { wallet} = React.useContext(WalletContext);
  return (
    <HeaderWrapper>
      <Typography variant='h3'>Gamblify</Typography>
      <Alert severity="info">This is a Beta Version.</Alert>
      {!wallet && <Link onClick={() => {}} variant="body2" color={'inherit'}>Connect</Link>}
      {wallet && <Link onClick={() => {}}variant="body2" color={'inherit'}>Disconect</Link>}
      {/* <div>Wallet address: {wallet}</div> */}
    </HeaderWrapper>
  );
}
