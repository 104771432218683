import React from 'react';
import styled from 'styled-components';
import { BigNumber, ethers } from 'ethers';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { MatchInterface } from '../../types/match';
import Fade from '@mui/material/Fade';

const RewardPoolWrapper = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
`;

const Spinner = styled(CircularProgress)`
  width: 1.5em !important;
  height: 1.5em !important;
`;

interface Props {
  match: MatchInterface;
  contractWithSigner: any;
  setRewardPool: React.Dispatch<React.SetStateAction<number>>;
  rewardPool: number;
  titleSize?: "button" | "caption" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "inherit" | "overline" | "subtitle1" | "subtitle2" | "body1" | "body2";
  valueSize?: "button" | "caption" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "inherit" | "overline" | "subtitle1" | "subtitle2" | "body1" | "body2";
}

export const RewardPool = ({ contractWithSigner, match, rewardPool, setRewardPool, titleSize, valueSize }: Props) => {
  const decimals = `1${Math.pow(10, parseInt(process.env.REACT_APP_POLYGON_STABLE_COIN_DECIMALS))}`
  const getMatchRewardPool = () => {

    contractWithSigner.rewardPool(parseInt(match.id))
    .then(response => {
      let bigNumber = BigNumber.from(response._hex)
      console.log(bigNumber)
      setRewardPool(bigNumber);
      //TODO: En polygon los decimales de USDT tiene 6 digitos... hay que parametrizar en arhcivo de environments para configuarlo ahi y hacer todas las conversiones...
    })
    .catch(err => {
      console.log(err);
      setRewardPool(0)
    });
  }

  React.useEffect(() => {
    getMatchRewardPool();
  }, []);

  return (
    <RewardPoolWrapper>
      <Typography variant={titleSize || "subtitle1"}>Reward pool</Typography>
      {!rewardPool && <Spinner />}
      <Fade in={!!rewardPool}><Typography variant={valueSize || "h2"}> {parseInt(rewardPool.toString())/parseInt(decimals)} {match.currency}</Typography></Fade>
    </RewardPoolWrapper>
  );
}
