import Typography from '@mui/material/Typography';
import React from 'react';
import styled from 'styled-components';
import { MatchOverview } from './MatchOverview';
import { MatchesContext } from '../../context/MatchesContext';

const MatchesWrapper = styled.div`
  padding-top: 36px;
`;

const MatchesList = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Matches = () => {
  const { matches } = React.useContext(MatchesContext);
  console.log(matches)
  return (<MatchesWrapper>
      <Typography variant="h2">Most played events</Typography>
      <MatchesList>
        { matches.filter(match => match.id != '').map(match => (
          <MatchOverview key={match.id} match={match}/>
        ))}
      </MatchesList>
    </MatchesWrapper>
  );
}
