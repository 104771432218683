import React from 'react';
import styled from 'styled-components';
import { useParams } from "react-router-dom";
import { ethers } from 'ethers';
import Typography from '@mui/material/Typography';

import { MatchInterface } from '../../types/match';
import { colorMediumBlue, colorBrightBlue } from '../../shared-css/colors';
import { WalletContext } from '../../context/WalletContext';
import { getMatchDateMMMDD } from '../../utils/match-date'
import { UserBet} from './UserBet';
import { RewardPool } from '../shared/RewardPool';
import { PlayersCount } from '../shared/PlayersCount';
import { MatchCountdown } from '../shared/MatchCountdown';
import { ContentWrapper } from '../../shared-css/content-wrapper';
import { Header } from './Header';
import { MatchesContext } from '../../context/MatchesContext';

const MatchWrapper = styled.div`
  background-color: ${colorMediumBlue};
  border: 1px solid ${colorBrightBlue};
  border-radius: 8px;
  padding: 1em;
  width: 100%;
`;

const MatchSection = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5em;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const MatchSectionCenter = styled(MatchSection)`
  align-items: center;
`

const TeamsWrapper = styled.div`
  display: flex;
`;

const Team = styled.div`
  padding-right: 1em;
  &:last-of-type {
    padding-right: 0;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TeamFlag = styled.img<{flag: string}>`
  border: 1px solid #FFFFFF;
  border-radius: 6px;
  background: url(${(props) => props.flag});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 4.3em;
  height: 3em;
`;

export const Match = () => {
  const params = useParams();
  const { matches } = React.useContext(MatchesContext);
  const match: MatchInterface = matches.filter((m: MatchInterface) => m.id.toString() === params.matchId)[0];

  const { provider, signer, wallet } = React.useContext(WalletContext);
  const [rewardPool, setRewardPool] = React.useState(0);
  const [contractWithSigner, setContractWithSigner] = React.useState(null);

  React.useEffect(() => {
    if(wallet) {
      let contract = new ethers.Contract(process.env.REACT_APP_OPTIONS_BET_ADDRESS, process.env.REACT_APP_OPTIONS_BET_ABI, provider)
      setContractWithSigner(contract.connect(signer))
    }
  }, [wallet])

  return (
    <>
    <Header match={match} />
      <ContentWrapper>
        <MatchWrapper>
          <MatchSection>
            <Typography variant="h2">Who will win?</Typography>
            <TeamsWrapper>
              <Team>
                <TeamFlag flag={match.local.flag} />
                <Typography variant="caption">{match.local.code}</Typography>
              </Team>
              <Team>
                <TeamFlag flag={match.visitor.flag} />
                <Typography variant="caption">{match.visitor.code}</Typography>
              </Team>
            </TeamsWrapper>
          </MatchSection>
          <MatchSection>
            <Typography variant="body1">{getMatchDateMMMDD(match.date)}</Typography>
            <MatchCountdown match={match} />
          </MatchSection>    
          {contractWithSigner && <MatchSectionCenter>
            <RewardPool contractWithSigner={contractWithSigner} match={match} rewardPool={rewardPool} setRewardPool={setRewardPool}/>
            <PlayersCount contractWithSigner={contractWithSigner} match={match} rewardPool={rewardPool}/>
          </MatchSectionCenter>}  
        </MatchWrapper>

        <UserBet contractWithSigner={contractWithSigner} match={match}/>
      </ContentWrapper>
    </>
  );
}
