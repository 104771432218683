import React from "react";
import { ethers, providers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";

interface Props {
  children: React.ReactNode;
}

const POLYGON_CHAINID = 137;

export interface WalletContextInterface {
  wallet: any;
  provider: any;
  signer: any;
  setSelectedProvider: React.Dispatch<React.SetStateAction<SelectedProvider>>;
  selectedProvider: string;
  supportedNetwork: boolean;
}

export const WalletContext = React.createContext<WalletContextInterface>(
  undefined!
);

export enum SelectedProvider {
  Metamask = 'metamask',
  WalletConnect = 'walletconnect'
};

export const WalletContextProvider = ({ children }: Props) => {
  const [wallet, setWallet] = React.useState("");
  const [provider, setProvider] = React.useState(null);
  const [signer, setSigner] = React.useState(null);
  const [supportedNetwork, setSupportedNetwork] = React.useState(false);
  const [selectedProvider, setSelectedProvider] = React.useState<SelectedProvider | null>(null);

  const setMetamaskAccount = async () => {
    if(!window.ethereum) return null;

    // Check existing connection
    const connectedAccounts = await window.ethereum.request({ method: "eth_accounts" });
    if(connectedAccounts.length) return setWallet(connectedAccounts[0]);

    // Else prompt user authorization
    const accounts = await window.ethereum.request({ method: "eth_requestAccounts" })
    setWallet(accounts[0]);
    const s = provider.getSigner();
    setSigner(s);
  }

  const setMetamaskProvider = () => {
    if(!window.ethereum) return null; // Metamask not installed
    const p = new ethers.providers.Web3Provider(window.ethereum);
    setProvider(p);
  };

  const setWCProvider = async () => {
    const wcProvider = new WalletConnectProvider({
      //infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
      chainId: 137,
      rpc: {
        137: "https://matic-mainnet.chainstacklabs.com"
      }
    });

    try {
      await wcProvider.enable();
      const p = new ethers.providers.Web3Provider(wcProvider);
      setProvider(p);
      setWallet(p.provider.accounts[0]);
    } catch (e) {
      console.log("Could not connect with WalletConnect: ", e);
      setSelectedProvider(null);
    }
  };

  const subscribeToWCEvents = () => {
    // Subscribe to accounts change
    provider && provider.on("accountsChanged", (accounts: string[]) => {
      console.log(accounts);
    });

    // Subscribe to session disconnection
    provider && provider.on("disconnect", (code: number, reason: string) => {
      console.log(code, reason);
    });
  };

  const checkSupportedNetwork = async () => {
    const network = await provider.getNetwork();
    const chainId = network.chainId;
    setSupportedNetwork(chainId === POLYGON_CHAINID);
  };

  React.useEffect(() => {
    if(selectedProvider) {
      switch(selectedProvider) {
        case SelectedProvider.Metamask: 
          return setMetamaskProvider();
        case SelectedProvider.WalletConnect:
          return setWCProvider();
      }
    }
  }, [selectedProvider]);

  React.useEffect(() => {
    if(!provider) return;
    
    switch(selectedProvider) {
      case SelectedProvider.Metamask: {
        setMetamaskAccount();
      }
      case SelectedProvider.WalletConnect: {
       subscribeToWCEvents();
       const s = provider.getSigner();
       setSigner(s);
      }
      default: {
        checkSupportedNetwork();
      }
    }
  }, [provider]);

  return (
    <WalletContext.Provider
      value={{
        wallet,
        provider,
        signer,
        setSelectedProvider,
        selectedProvider,
        supportedNetwork,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

/*
await provider.disconnect()
*/